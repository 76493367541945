const Footer = () => {
  return (
    <footer className="footer position-fixed bottom-0 w-100">
      <div className="container">
        <ul className="menu">
          <li>
            <a href="#">Privacy Policy</a>
          </li>
          <li>
            <a href="#">Terms and Conditions</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
